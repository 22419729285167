import Hero from "@components/shared/productHero"
import SanityIllustrationCTABanner from "@components/shared/SanityIllustrationCtaBanner"
import SanityIllustrationText from "@components/shared/SanityIllustrationText"
import SEO from "@components/seo"
import { Container } from "@util/standard"
import Layout from "@components/layout"
import React from "react"
import { graphql, PageProps } from "gatsby"
import { PageContext } from "@util/types"
import BlogsFeed from "./blogsFeed"

interface Props extends PageProps {
  data: any
  pageContext: PageContext
}

const PageTemplate = (props: Props) => {
  const data = props.data.sanityLandingPage

  if (data == null) return null

  return (
    <Layout>
      <SEO
        title={data.seo ? data.seo.pageTitle : null}
        description={data.seo ? data.seo.pageDescription : null}
        keywords={data.seo ? data.seo.pageKeywords : null}
        image={data.seo ? data.seo.ogImage : null}
        url={`${data.slug.current}`}
      />
      {/* Hero Banner */}
      {data.productHero && <Hero data={data.productHero} />}

      {/* Bellow Fold */}
      {data.illustratedCta &&
        data.illustratedCta.ctaIllustration &&
        data.illustratedCta.ctaIllustration.asset && (
          <SanityIllustrationCTABanner
            data={data.illustratedCta}
            displayTopSVG
          />
        )}
      {/* Alternating hero features */}
      <Container>
        <Container
          maxWidth="1500px"
          margin="80px auto 0"
          width="95%"
          flexDirection="column"
        >
          {data.altFeatures.map(feature => {
            if (feature._type === "illustrationText") {
              return <SanityIllustrationText feature={feature} />
            }
            if (feature._type === "illustrationCtaBanner") {
              return <SanityIllustrationCTABanner data={feature} />
            }
          })}
        </Container>
      </Container>

      <BlogsFeed data={data.blogReference} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($slug: String) {
    sanityLandingPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }

      altFeatures {
        ... on SanityIllustrationText {
          ...sanityIllustrationText
        }
        ... on SanityIllustrationCtaBanner {
          ...sanityIllustrationCtaBanner
        }
      }
      blogReference {
        ...sanityProductBlogReference
      }

      productHero {
        ...sanityProductHero
      }
      illustratedCta {
        ...sanityIllustrationCtaBanner
      }
    }
  }
`
